<template>
    <div class="global-content">
        <filtrate :date="initTime" />
        <div class="inner">
             <div v-if="!emptyData">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <div class="block" v-for="(item,index) in dataList" :key="index">
                        <div class="flex bottom-line">
                            <div>名称：{{item.devName}}</div>
                            <div>编号：{{item.devCode}}</div>
                        </div>
                        <div class="info">
                            <div class="type">过车数量：{{item.carCount}} 辆</div>
                            <div class="type">平均车速：{{item.carSpeed}} Km/h</div>
                            <div class="type">统计周期：{{item.period}} s</div>
                        </div>
                        <div class="time top-line">地理位置：{{item.devRoad || '暂未设置地址'}}</div>
                        <div class="time">更新时间：{{item.devTime}}</div>
                    </div>
                </van-list>
             </div>
            <div v-else><van-empty description="没有更多数据了" /></div>
        </div>
    </div>
</template>
<script>
import filtrate from '@/components/itgs/filtrate.vue';
const Today = new Date();
var Yesterday = new Date();
Yesterday.setTime(Yesterday.getTime()-24*60*60*1000);
export default {
    name:'Warn',
    components: {
        filtrate
    },
    data(){
        return{
            emptyData:false,
            loading:false,
            finished:false,
            pageNum:1,
            pageSize:10,
            filter:{
                devCode:'',
                start:'',
                end:'',
            },
            dataList:[],
            initTime:[
                `${Yesterday.getFullYear()}-${Yesterday.getMonth() > 8 ? Yesterday.getMonth() + 1 : '0' + (Yesterday.getMonth() + 1)}-${Yesterday.getDate()>9?Yesterday.getDate():'0'+Yesterday.getDate()}`,
                `${Today.getFullYear()}-${Today.getMonth() > 8 ? Today.getMonth() + 1 : '0' + (Today.getMonth() + 1)}-${Today.getDate()>9?Today.getDate():'0'+Today.getDate()}`
            ]
        }
    },
    mounted(){
    },
    methods:{
        onLoad(tag){
            if(tag){
                this.filter.devCode = tag.devCode;
                this.filter.start = tag.start;
                this.filter.end = tag.end;
                this.dataList = [];
                this.finished=false;
                this.pageNum = 1; //筛选时间  重置页码
            }
            const projectCode = window.sessionStorage.getItem('projectCode');
            let postData = {
                beginTime :this.filter.start || this.initTime?.[0],
                endTime :this.filter.end || this.initTime?.[1],
                devCode:this.filter.devCode,
                projectCode:projectCode
            };
            this.$api.ITGS.MonitorCarStat(this.pageNum,this.pageSize,postData).then((data)=>{
                data.total == 0 ? this.emptyData = true : this.emptyData = false;
                if(this.dataList.length==0){
                    this.dataList = data.records;
                }else{
                    this.dataList = this.dataList.concat(data.records);
                }
                data.pages == this.pageNum ? this.finished = true : '' ;
                this.loading = false;
                this.pageNum ++;
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.van-empty{margin: 25% 0;}
.inner{
    .block{
        padding: 4%;
        background-color: #fff;
        font-size: .8rem;
        border: 1px solid #ececec;
        margin-top: 2%;
        .bottom-line{padding-bottom: 2%;border-bottom: 1px solid #f0f0f0;}
        .top-line{padding-top: 2%;border-top: 1px solid #f0f0f0;}
        .flex{
            display: flex;
            justify-content:space-between;
            margin: 2% 0;
            font-weight: bold;
        }
        .info div{margin: 2% 0;color: #0957ff;}
        .time{color: #656565;margin-top:2%;}
    }
}
</style>